import { useState } from 'react';
import Modal from 'react-modal';


export default function ViewModal ({cta, children}){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <button className='p-0 text-start' onClick={handleShow}>
          <p className='text-base font-dmSans hover:text-blue-300'>{cta}</p>
        </button>
  
        <Modal style = {customStyles} isOpen={show} onRequestClose={handleClose} centered>
          <div className='p-4 pb-0 text-blue-700 flex justify-between items-center'>
            <p className='text-xl font-semibold text-blue-700'>{cta}</p>
            <button variant="primary" onClick={handleClose}>X</button>
          </div>
          <div className='p-3'>{children}</div>
        </Modal>
      </>
    )
}

const customStyles = {
  content: {
    width : '500px',
    transition: ".5s",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding:'40px 20px',
    overflowY:'hidden',
    height:'550px'
  },
};