const Email = ({color}) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={23}
      fill={color}
    >
      <path
        fill={color}
        fillOpacity={0.7}
        d="M19.25 4.625H2.75a.688.688 0 0 0-.688.688V17a1.375 1.375 0 0 0 1.376 1.375h15.124A1.375 1.375 0 0 0 19.938 17V5.312a.687.687 0 0 0-.687-.687ZM17.482 6 11 11.943 4.518 6h12.964Zm1.08 11H3.438V6.876l7.098 6.506a.687.687 0 0 0 .93 0l7.098-6.506V17Z"
      />
    </svg>
  )
  export default Email  