const Instagram = ({color}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={23}
    fill={color}
  >
    <path
      fill={color}
      fillOpacity={0.7}
      d="M11 7.375a4.125 4.125 0 1 0 0 8.25 4.125 4.125 0 0 0 0-8.25Zm0 6.875a2.75 2.75 0 1 1 0-5.5 2.75 2.75 0 0 1 0 5.5Zm4.125-11.688h-8.25a4.818 4.818 0 0 0-4.813 4.813v8.25a4.818 4.818 0 0 0 4.813 4.813h8.25a4.817 4.817 0 0 0 4.813-4.813v-8.25a4.818 4.818 0 0 0-4.813-4.813Zm3.438 13.063a3.438 3.438 0 0 1-3.438 3.438h-8.25a3.438 3.438 0 0 1-3.438-3.438v-8.25a3.437 3.437 0 0 1 3.438-3.438h8.25a3.438 3.438 0 0 1 3.438 3.438v8.25ZM16.5 7.031a1.031 1.031 0 1 1-2.063 0 1.031 1.031 0 0 1 2.063 0Z"
    />
  </svg>
)
export default Instagram