import Link from "next/link";

//Componente con props

export default function ButtonMain ({href, cta, clase, claseText}){

    return(
        <>
        <button className={`rounded-full py-2 px-2 md:px-5 border-2 hover:bg-blue-400 hover:text-white hover:border-transparent transition-all ${clase}`}>
            <Link href={href}><p className={`font-dmSans font-medium ${claseText}`}>{cta}</p></Link>
        </button>
        
        </>
    )
}