
export default function ButtonSummit ({click, cta, color, hover, clase, claseText}){
    return(
        <>
        <button onClick={click} className={`border rounded-full py-2 px-2 md:px-5 border-solid border-${color} hover:bg-blue-400 text-${color} hover:text-white hover:border-transparent transition-all ${clase}`}>
            <p className={`font-dmSans font-medium ${claseText}`}>{cta}</p>
        </button>
        
        </>
    )
}