const Linkedin = ({color}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={23}
    fill={color}
  >
    <path
      fill={color}
      fillOpacity={0.7}
      d="M18.563 2.563H3.438a1.375 1.375 0 0 0-1.376 1.374v15.126a1.375 1.375 0 0 0 1.376 1.375h15.124a1.375 1.375 0 0 0 1.375-1.375V3.938a1.375 1.375 0 0 0-1.375-1.376Zm0 16.5H3.438V3.938h15.124v15.124ZM8.25 10.124v5.5a.687.687 0 0 1-1.375 0v-5.5a.688.688 0 1 1 1.375 0Zm7.563 2.406v3.094a.687.687 0 1 1-1.376 0v-3.094a1.719 1.719 0 0 0-3.437 0v3.094a.687.687 0 1 1-1.375 0v-5.5a.687.687 0 0 1 1.357-.153 3.093 3.093 0 0 1 4.83 2.56ZM8.593 7.72a1.031 1.031 0 1 1-2.062 0 1.031 1.031 0 0 1 2.063 0Z"
    />
  </svg>
)
export default Linkedin
