import Link from "next/link";
import { ArrowRightIcon } from '@heroicons/react/24/solid';

export default function ButtonCard ({href, cta, clase, claseText, iconColor}){

    return(
        <>
        <button className={`rounded-full py-1 px-2 border transition-all flex items-center justify-center gap-2 hover:scale-95 ${clase}`}>
            <Link href={href} target="blank"><p className={`font-dmSans font-medium text-base ${claseText}`}>{cta}</p></Link>
            <ArrowRightIcon className={`w-5 ${iconColor}`} />
        </button>
        
        </>
    )
}