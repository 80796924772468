
export default function Less_button () {
 return(
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none">
      <circle cx={15} cy={15} r={15} fill="currentColor" />
      <path fill="#fff" d="M13.816 13.465H8.334v2.403h13.333v-2.403H13.816Z" />
    </svg>
  </>
  )
} 
