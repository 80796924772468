export default function Pluss_button ({addClass}) {
    return (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none" className={addClass}>
          <circle cx={15} cy={15} r={15} fill="currentColor" />
          <path
            fill="#fff"
            d="M13.818 7.667v6.132H8.336V16.2h5.482v6.133h2.369V16.2h5.482V13.8h-5.482V7.667h-2.369Z"
          />
        </svg>
      </>
)}
