import Link from "next/link";

export default function Termos (){
    return(
        <>
        <div className="h-96 overflow-y-auto p-2 overflow-color font-dmSans grid gap-4">
            <p>
            Bem-vindo à nossa plataforma de software! Ao acessar e utilizar nossos serviços, você concorda com os seguintes termos e condições. Por favor, leia atentamente antes de continuar utilizando a plataforma.
            </p>
            <div>
                <p className="text-lg font-medium">1. Uso da Plataforma</p>
                <p>
                Ao utilizar nossa plataforma, você concorda em obedecer às leis e regulamentos aplicáveis. Você concorda em não utilizar a plataforma para qualquer finalidade ilegal, prejudicial ou não autorizada, e concorda em cumprir todas as leis locais, nacionais e internacionais.
                </p>
            </div>
            <div>
                <p className="text-lg font-medium">2. Conta de Usuário</p>
                <p>
                Ao se registrar em nossa plataforma, você concorda em fornecer informações precisas e atualizadas. Você é responsável por manter a confidencialidade de suas credenciais de login e por todas as atividades que ocorrerem em sua conta.
                </p>
            </div>
            <div>
                <p className="text-lg font-medium">3. Conteúdo do Usuário</p>
                <p>
                Ao enviar informações, currículos, feedback ou outros tipos de conteúdo à plataforma, você concede a nós uma licença não exclusiva, transferível, sublicenciável, isenta de royalties e mundial para usar, exibir, reproduzir, adaptar, modificar e distribuir esse conteúdo.
                </p>
            </div>
            <div>
                <p className="text-lg font-medium">4. Propriedade Intelectual</p>
                <p>
                Todo o conteúdo presente em nossa plataforma, incluindo textos, gráficos, logotipos, ícones e imagens, é protegido por direitos autorais e outras leis de propriedade intelectual. Você não pode copiar, reproduzir, distribuir, transmitir, exibir, vender, licenciar ou explorar qualquer conteúdo para fins comerciais sem nossa autorização expressa por escrito.
                </p>
            </div>
            <div>
                <p className="text-lg font-medium">5. Interrupções e Modificações</p>
                <p>
                Reservamos o direito de modificar, interromper ou suspender temporária ou permanentemente a plataforma, ou partes dela, com ou sem aviso prévio. Não seremos responsáveis por quaisquer danos resultantes de tais ações.
                </p>
            </div>
            <div>
                <p className="text-lg font-medium">6. Limitação de Responsabilidade</p>
                <p>
                Nossa plataforma é fornecida "como está" e não fazemos representações ou garantias de qualquer tipo, expressas ou implícitas, sobre a precisão, confiabilidade ou adequação do conteúdo e dos serviços oferecidos.
                </p>
            </div>
            <div>
                <p className="text-lg font-medium">7. Links para Terceiros</p>
                <p>
                Nossa plataforma pode conter links para sites de terceiros. Não somos responsáveis pelo conteúdo ou pelas práticas de privacidade desses sites. O acesso a qualquer site de terceiros é por sua conta e risco.
                </p>
            </div>
            <div>
                <p className="text-lg font-medium">8. Rescisão</p>
                <p>
                Reservamos o direito de rescindir ou suspender sua conta e acesso à plataforma a qualquer momento, por qualquer motivo, sem aviso prévio.
                </p>
            </div>
            <div>
                <p className="text-lg font-medium">9. Disposições Gerais</p>
                <p>
                Estes termos constituem o acordo completo entre você e nós em relação ao uso da plataforma. Qualquer falha nossa em exercer ou fazer cumprir qualquer direito ou disposição destes termos não constituirá uma renúncia a tal direito ou disposição.
                </p>
            </div>
            <div>
                <p className="text-lg font-medium">10. Alterações nos Termos</p>
                <p>
                Podemos atualizar estes Termos de Uso periodicamente. Você será notificado sobre alterações significativas. O uso continuado da plataforma após as alterações constitui a aceitação dos novos termos.
                </p>
            </div>
            <p>
            Se você tiver alguma dúvida sobre estes Termos de Uso, entre em contato conosco por meio dos canais indicados em nossa plataforma.
            </p>
            <p>
            Agradecemos por escolher a nossa plataforma e concordar com estes termos.
            </p>
            <p>Contato <Link href='mailto:hello@linquei.com' className='hover:text-blue-300 underline'>hello@linquei.com</Link></p>
            <p>
            Última atualização: 03/03/2023
            </p>

        </div>
        </>
    )
}