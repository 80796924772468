import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import ButtonMain from '../buttons/button';

const navigation = [
  { name: 'Home', href: 'index', current: false, hover:'decoration-red' },
  { name: 'Linquei Conselhos', href: 'conselhos', current: false, hover:'decoration-orange' },
  { name: 'Linquei Estágios', href: 'estagios', current: false, hover:'decoration-yellow' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header({logo}) {
  return (
    <>
      <div className="text-blue-700 font-encode sticky top-0 z-50 text-lg w-full">
        <Disclosure as="nav" className="bg-white">
          {({ open }) => (
            <>  
                {/* Set General Nav */}
                <div className="mx-auto max-w-screen-xl py-5 px-4 xl:px-0 sm:px-6 lg:px-8">
                    <div className="flex h-16 items-center justify-between">

                        {/* Logotipo */}
                        <div className="w-52">
                            {logo}
                        </div>
        
                        {/* Nav */}
                        <div className="hidden md:block">
                        <div className="ml-10 flex items-baseline space-x-4 ">
                            {navigation.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className={classNames(
                                  item.current ? `underline decoration-2 underline-offset-4 ${item.hover}` : `hover:font-bold transition-all hover:underline decoration-2 underline-offset-4 ${item.hover}`,
                                  'rounded-md px-3 py-2'
                                )}
                                aria-current={item.current ? item.name : undefined}
                            >
                                {item.name}
                            </a>
                            ))}
                        </div>
                        </div>
                        
                        {/* Button */}
                        <div className="hidden">
                            <ButtonMain href='#' cta='Entrar' clase='text-blue-400 md:px-16 border-blue-400' claseText='text-lg' />
                        </div>

                        {/* Button Responsive */}
                        <div className="-mr-2 flex md:hidden">
                            
                            <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-transparent p-2 text-blue-700 hover:bg-blue-700 hover:text-white transition-all">
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Open main menu</span>
                            {open ? (
                                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                            ) : (
                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                            )}
                            </Disclosure.Button>
                        </div>

                    </div>
                </div>
            
                {/* Nav Responsive */}
                <Disclosure.Panel className="md:hidden">
                    <div className="space-y-5 px-2 pb-3 pt-2 sm:px-3">
                    {navigation.map((item) => (
                        <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                            item.current ? `underline decoration-2 underline-offset-4 ${item.hover}` : `hover:font-bold hover:underline decoration-2 underline-offset-4 ${item.hover}`,
                            'block rounded-md px-3 py-2 text-lg'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                        >
                        {item.name}
                        </Disclosure.Button>
                    ))}
                    <ButtonMain href='#' cta='Entrar' clase='text-blue-400 md:px-10 border-blue-400 w-full hidden' claseText='text-lg' />
                    </div>
                </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  )
}
