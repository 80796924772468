import ButtonSummit from '@/components/buttons/buttonSummit'
import ViewModal from '@/components/modal/modal'
import Privacidade from '@/components/modal/privacidade'
import Termos from '@/components/modal/termos'
import Email from '@/public/icons/email'
import Instagram from '@/public/icons/instagram'
import Linkedin from '@/public/icons/linkedin'
import Link from 'next/link'
import Modal from 'react-modal';
import { useState } from 'react'
import ButtonCard from '../buttons/button_card'

export default function Footer ({callToAction, logo, isotipo}){

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const formsend = (e) => {
    e.preventDefault()

    let form = e.target
    let url = form.action
    let name = form.elements["name"].value
    let email = form.elements["email"].value
    let message = form.elements["message"].value

    let body = {
      name,email,message
    }
  
    fetch(url,{
      method : "POST",
      body : JSON.stringify(body),
      headers : {"Content-type": "application/json; charset=UTF-8"}
    }).then(response => response.json())
    .then(json => {
      openModal()
      setTimeout(() => {
        closeModal()
      },2000)
    })
  }

    return(
        <>
    {/* Section Contact */}
    <section className='pt-24 px-10 xl:px-0 bg-texture bg-cover'>
      <div className='mx-auto max-w-screen-xl'>
        <div className='flex flex-col md:flex-row justify-between md:items-center gap-10 mx-auto max-w-screen-xl text-white mb-40 '>
          <p className='text-3xl md:text-4xl font-encode w-full md:w-8/12' data-aos="fade-left">{callToAction}</p>
          <ButtonCard href={'mailto:contato@linquei.com'} cta='Entrar em contato' clase='border-white py-2 px-8' claseText='text-white text-xl' iconColor='text-white'/>
        </div>

        <div className=' text-white flex flex-col md:flex-row gap-16 md:gap-24'>
            <div className='w-full flex flex-col gap-4'>
              <div className='mb-5 w-56'>
                {logo}
              </div>
              <div className='flex gap-4 font-dmSans'>
                <div><Email color='white'/></div>
                <Link href='mailto:contato@linquei.com'><p className='text-base'>contato@linquei.com</p></Link>
              </div>
              <div className='flex gap-4 font-dmSans'>
                <div><Instagram color='white'/></div>
                <Link href='https://instagram.com/linquei_?igshid=MzRlODBiNWFlZA' target='blank'><p className='text-base'>@linquei</p></Link>
              </div>
              <div className='flex gap-4 font-dmSans'>
                <div><Linkedin color='white'/></div>
                <Link href='https://www.linkedin.com/company/linquei/about/' target='blank'><p className='text-base'>/Linquei</p></Link>
              </div>
              <ViewModal cta='Política de privacidade'>
                <Privacidade />
              </ViewModal>
              <ViewModal cta='Termos de uso'>
                <Termos />
              </ViewModal>
            </div>

            <form onSubmit={formsend} method='POST' action='/api/email'   className='w-full flex flex-col gap-4 p-10 bg-gray-50 bg-opacity-5 rounded-xl border' id='contato'>
              <p className='text-3xl font-encode mb-5'>Entre em contato</p>
              <input required name = "name" type='text' placeholder='Nome' className='p-3 bg-transparent border-b-2 hover:outline-none focus:outline-none placeholder:text-white focus:border-blue-400'></input>
              <input required name='email' type='Email' placeholder='Email' className='p-3 bg-transparent border-b-2 hover:outline-none focus:outline-none placeholder:text-white focus:border-blue-400'></input>
              <textarea required name='message' placeholder='Mensagem' className='p-3 bg-transparent border-b-2 hover:outline-none focus:outline-none placeholder:text-white focus:border-blue-400'></textarea>
              <ButtonSummit cta='Enviar mensagem' color='white' clase='mt-5 w-100 md:w-52'/>
            </form>

            <Modal      
            ariaHideApp = {true}   
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            closeTimeoutMS={300}
             >
              <div>Sua mensagem foi enviada com sucesso!</div>
            </Modal>
        </div>

        <div className='mt-20 pb-10 text-white flex flex-col md:flex-row gap-5 justify-center items-center'>
            {isotipo}
          <p>Todos os direitos reservados</p>
        </div>
      </div>
    </section>
        </>
    )
}

const customStyles = {
  content: {
    width : '380px',
    display : 'flex',
    justifyContent:'center',
    height : '70px',
    top: '10%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#1e285f',
    color: 'white',
    border : '1px color white',
    borderRadius : '8px',
    transition: ".5s",
  },
};