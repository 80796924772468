import Link from "next/link";

export default function Privacidade (){
    return(
        <>
        <div className="h-96 overflow-y-auto p-2 overflow-color font-dmSans grid gap-4">
            <p>
            Bem-vindo à nossa plataforma de software! A sua privacidade é extremamente importante para nós. Esta Política de Privacidade visa esclarecer como coletamos, utilizamos, compartilhamos e protegemos as informações pessoais que você nos fornece ao usar os nossos serviços. Recomendamos que você leia atentamente este documento para entender como cuidamos dos seus dados.
            </p>
            <div>
                <p className="text-lg font-medium">1. Informações que Coletamos</p>
                <p>
                Coletamos informações que você nos fornece diretamente ao se registrar, usar nossos serviços, interagir com a plataforma ou nos contatar. Isso pode incluir informações como nome, endereço de e-mail, informações curriculares e preferências de vaga.
                </p>
            </div>
            <div>
                <p className="text-lg font-medium">2. Uso das Informações</p>
                <p>
                Utilizamos as informações coletadas para personalizar sua experiência na plataforma, melhorar nossos serviços, fornecer recomendações relevantes de vagas e cursos, facilitar a conexão entre profissionais e oportunidades, além de cumprir obrigações legais.
                </p>
            </div>
            <div>
                <p className="text-lg font-medium">3. Compartilhamento de Informações</p>
                <p>
                Nós podemos compartilhar suas informações com parceiros e prestadores de serviços que auxiliam no funcionamento da plataforma, sempre garantindo que suas informações sejam tratadas com a máxima confidencialidade. Não vendemos, alugamos ou compartilhamos suas informações pessoais com terceiros para fins de marketing.
                </p>
            </div>
            <div>
                <p className="text-lg font-medium">4. Segurança dos Dados</p>
                <p>
                Implementamos medidas de segurança rigorosas para proteger as informações pessoais contra acesso não autorizado, uso indevido ou divulgação. No entanto, é importante lembrar que nenhum método de transmissão pela internet ou armazenamento eletrônico é totalmente seguro.
                </p>
            </div>
            <div>
                <p className="text-lg font-medium">5. Seus Direitos e Escolhas</p>
                <p>
                Você tem o direito de acessar, corrigir ou excluir suas informações pessoais. Além disso, você pode optar por não receber comunicações de marketing. Entre em contato conosco para exercer esses direitos.
                </p>
            </div>
            <div>
                <p className="text-lg font-medium">6. Cookies e Tecnologias Semelhantes</p>
                <p>
                Utilizamos cookies e outras tecnologias para melhorar a sua experiência na plataforma, entender o uso que você faz dos nossos serviços e personalizar conteúdos. Você pode controlar o uso de cookies nas configurações do seu navegador.
                </p>
            </div>
            <div>
                <p className="text-lg font-medium">7. Alterações nesta Política</p>
                <p>
                Podemos atualizar esta Política de Privacidade ocasionalmente para refletir mudanças em nossas práticas e serviços. Recomendamos que você verifique periodicamente esta página para estar ciente das atualizações.
                </p>
            </div>
            <p>
            Se você tiver dúvidas sobre esta Política de Privacidade ou sobre o tratamento de suas informações, entre em contato conosco através dos canais indicados em nossa plataforma.
            </p>
            <p>
            Obrigado por escolher a nossa plataforma. Estamos comprometidos em proteger a sua privacidade e proporcionar uma experiência segura e valiosa.
            </p>
            <p>Contato <Link href='mailto:hello@linquei.com' className='hover:text-blue-300 underline'>hello@linquei.com</Link></p>
            <p>
            Última atualização: 03/03/2023
            </p>

        </div>
        </>
    )
}